
import React, { Suspense,useContext } from "react"
import { useLocation } from '@reach/router';

import useModal from "../../hooks/useModal"
import { FinanceCalculatorContext } from '../modal/financing/storage/CalculatorContext';

const AvailabilityForm = React.lazy(() => import("../modal/content/AvailabilityForm"));
const FinanceForm = React.lazy(() => import("../modal/content/FinanceForm"));
const CalculatorModal = React.lazy(() => import("../modal/financing/CalculatorModal"));

export default function VDPModalContainer(props) {
  const [context] = useContext(FinanceCalculatorContext)
  const { lead_url, site_id, gtmId } = props.siteData
  const { disclaimerContact, disclaimerFinanceApp, disclaimerCalculator } = props.siteData
  const { defaultMonth, downpayment, rates } = props.financeData
  const document = typeof window !== 'undefined' && window.document

  let ModalTitle = ""
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  for (const [key, value] of params) {
    if(key === "title"){
      ModalTitle = value
    }

  }

  const [renderAvailabilityFormModal, visibleAFM] = useModal({
    title: ModalTitle,
    location: "#contact"
  })

  const [renderFinanceAppFormModal, visibleFFM] = useModal({
    title: ModalTitle,
    location: "#financeapp"
  })

  const [renderFinanceCalculatorModal, visibleFCM] = useModal({
    title: ModalTitle != "" ? ModalTitle : "ESTIMATE PAYMENT",
    location: "#financecal",
    responsiveFluid: true,
  })

  const availabilityFormModal = typeof window !== 'undefined' &&
    (
      <Suspense fallback={<div>Loading...</div>}>
        <AvailabilityForm contextualized gtmId={gtmId} siteID={site_id} leadURL={lead_url}
          disclaimerContact={disclaimerContact} autofillable
          pageSource='Contact' pageURL={document.URL}
        />
      </Suspense>
    )

  const financeAppFormModal = typeof window !== 'undefined' &&
    (
      <Suspense fallback={<div>Loading...</div>}>
        <FinanceForm contextualized gtmId={gtmId} siteID={site_id} leadURL={lead_url}
          disclaimerFinanceApp={disclaimerFinanceApp} autofillable
          pageSource='FinanceApp' pageURL={document.URL}
        />
      </Suspense>
    )

  const financeCalculatorModal = typeof window !== 'undefined' &&
    (
      <Suspense fallback={<div>Loading...</div>}>
        <CalculatorModal contextualized gtmId={gtmId} siteID={site_id} leadURL={lead_url}
          defaultMonth={defaultMonth} downpayment={downpayment} rates={rates} autofillable
          disclaimerCalculator={disclaimerCalculator} />
      </Suspense>
    )

  return (
    <>
      {(visibleAFM && Object.keys(context.vehicleInfo).length > 0) && renderAvailabilityFormModal(availabilityFormModal)}
      {(visibleFFM && Object.keys(context.vehicleInfo).length > 0) && renderFinanceAppFormModal(financeAppFormModal)}
      {(visibleFCM && Object.keys(context.vehicleInfo).length > 0) && renderFinanceCalculatorModal(financeCalculatorModal)}
    </>
  )
}